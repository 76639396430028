/*
    Looks a bit weird because this is also used to generate the sitemap,
    using https://github.com/cheap-glitch/vue-cli-plugin-sitemap.

    For example, we need to use .. instead of @ and dynamic imports for
    each "component:". See above link for details.
*/

// eslint-disable-next-line import/prefer-default-export
export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Landing.vue'),
    meta: { redirToUploadIfLoggedIn: true },
  },
  {
    path: '/landing',
    name: 'Landing',
    component: () => import('../views/Landing.vue'),
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing.vue'),
  },
  {
    path: '/purchase-success',
    name: 'PurchaseSuccess',
    component: () => import('../views/PurchaseSuccess.vue'),
    props: route => ({
      stripeSessionId: route.query.session_id,
    }),
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/Upload.vue'),
  },
  {
    path: '/jobs/:id',
    name: 'Job',
    component: () => import('../views/Job.vue'),
    meta: {
      requiresAuth: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { redirToUploadIfLoggedIn: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { redirToUploadIfLoggedIn: true },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    meta: { redirToUploadIfLoggedIn: true },
  },
  {
    path: '/signup/verify',
    name: 'VerifyEmail',
    component: () => import('../views/VerifyEmail.vue'),
    meta: {
      requiresAuth: true,
      redirToUploadIfEmailVerified: true,
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    component: () => import('../views/Affiliates.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];
