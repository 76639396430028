import { createStore } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
  updateProfile,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
} from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { mySendEmailVerification, db } from '@/utils/firebase';
import { msgFromFirebaseAuthError } from '@/utils/validation';
import AudioDurationWorker from '@/utils/audioMetadata.worker';
import { deepSameKeys } from '@/utils/misc';

function getDefaultOptions() {
  return {
    normalization: {
      enabled: true,
    },
    leveling: {
      enabled: true,
    },
    noiseReduction: {
      enabled: false,
    },
    silenceTrimming: {
      enabled: false,
    },
    output: {
      optimize: true,
    },
  };
}

const form = {
  namespaced: true,
  state: {
    file: null,
    fileMeta: null,
    options: getDefaultOptions(),
  },
  getters: {
    getField,
    file: state => state.file,
    options: state => state.options,
    audioDurationSec: state => (
      state.fileMeta ? Math.round(state.fileMeta.format.duration) : null
    ),
  },
  mutations: {
    updateField,
    SET_FILE(state, file) { state.file = file; },
    SET_FILE_META(state, fileMeta) { state.fileMeta = fileMeta; },
    SET_OPTIONS(state, options) { state.options = options; },
  },
  actions: {
    async setFile({ commit }, file) {
      if (file === null) {
        commit('SET_FILE', null);
        commit('SET_FILE_META', null);
      } else {
        commit('SET_FILE_META', null);
        commit('SET_FILE', file);
        const worker = new AudioDurationWorker();
        worker.onmessage = e => { commit('SET_FILE_META', e.data); };
        worker.onerror = e => { console.error(e); };
        worker.postMessage({ file }); // call worker with file
      }
    },
    async clearFile({ dispatch }) {
      dispatch('setFile', null);
    },
    async setOptions({ commit }, options) {
      if (deepSameKeys(options, getDefaultOptions())) {
        commit('SET_OPTIONS', options);
      } else {
        console.warn('form/setOptions: Tried to set options with invalid keys');
      }
    },
  },
};

const auth = {
  namespaced: true,
  state: {
    user: null,
    userMeta: null,
    userMetaKnown: false,
    userMetaUnsubFn: null,
    error: null,
  },
  getters: {
    user: state => state.user,
    // Do we know if user logged in?
    // Needed because user===null means either user is not logged in
    // OR we don't know if user is logged in.
    userMeta: state => state.userMeta,
    userMetaKnown: state => state.userMetaKnown,
    error: state => state.error,
    isLoggedIn: state => !!state.user,
    isVerified: state => !!(state.user && state.user.emailVerified),
    creditsRemainingSec: state => (
      state.userMeta && state.userMeta.creditsRemainingSec
        ? (state.userMeta.creditsRemainingSec.recurring
           + state.userMeta.creditsRemainingSec.oneTime)
        : null
    ),
    isSubscribed: state => !!(state.userMeta && state.userMeta.currentSubscription),
    subscriptionName: state => (
      state.userMeta && state.userMeta.currentSubscription
        ? state.userMeta.currentSubscription.productName
        : null
    ),
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_META(state, userMeta) {
      state.userMeta = userMeta;
      state.userMetaKnown = true;
    },
    SET_USER_META_UNSUB_FN(state, unsubFn) {
      state.userMetaUnsubFn = unsubFn;
    },
    SET_ERROR(state, msg) { state.error = msg; },
    SET_ERROR_FROM_FIREBASE(state, error) { state.error = msgFromFirebaseAuthError(error); },
  },
  actions: {
    setUser({ state, commit }, user) {
      commit('SET_USER', user);
      if (state.userMetaUnsubFn) {
        state.userMetaUnsubFn();
        commit('SET_USER_META_UNSUB_FN', null);
      }
      if (user) {
        const unsubFn = onSnapshot(doc(db, 'users', user.uid), snapshot => {
          commit('SET_USER_META', snapshot.data());
        });
        commit('SET_USER_META_UNSUB_FN', unsubFn);
      } else {
        commit('SET_USER_META', null);
      }
    },
    loginEmailPassword({ commit, dispatch }, { email, password }) {
      commit('SET_ERROR', null);
      const authObj = getAuth();

      fetchSignInMethodsForEmail(authObj, email).then(methods => {
        if (methods.includes('google.com')) {
          commit('SET_ERROR', 'Your account is already linked with Google.\nClick "Continue with Google" to log in.');
          return;
        }
        signInWithEmailAndPassword(getAuth(), email, password)
          .then(result => {
            dispatch('setUser', result.user);
          })
          .catch(error => commit('SET_ERROR_FROM_FIREBASE', error));
      }).catch(error => commit('SET_ERROR_FROM_FIREBASE', error));
    },
    // only used by administrators
    loginCustomToken({ commit, dispatch }, { token }) {
      commit('SET_ERROR', null);
      const authObj = getAuth();
      signInWithCustomToken(authObj, token)
        .then(result => {
          dispatch('setUser', result.user);
        })
        .catch(error => commit('SET_ERROR_FROM_FIREBASE', error));
    },
    loginGoogle({ commit }) {
      commit('SET_ERROR', null);
      const authObj = getAuth();
      authObj.useDeviceLanguage();
      signInWithRedirect(authObj, new GoogleAuthProvider());
    },
    async signupEmailPassword({ commit, dispatch }, { name, email, password }) {
      commit('SET_ERROR', null);
      try {
        const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
        await updateProfile(user, { displayName: name });
        dispatch('setUser', user);
        await mySendEmailVerification(user);
      } catch (error) {
        commit('SET_ERROR_FROM_FIREBASE', error);
      }
    },
    logout({ commit, dispatch }) {
      commit('SET_ERROR', null);
      signOut(getAuth())
        .then(() => dispatch('setUser', null))
        .catch(error => commit('SET_ERROR_FROM_FIREBASE', error));
    },
  },
};

const misc = {
  namespaced: true,
  state: {
    signupBannerClosed: false,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
};

export default createStore({
  modules: {
    form,
    auth,
    misc,
  },
});
