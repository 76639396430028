import { AuthError } from 'firebase/auth';
import firebaseErrorMessages from '@/resources/firebase-auth-errors.json';

export function validatePassword(password: string): string | null {
  if (!password) {
    return 'Please enter a password.';
  }
  if (password.length < 6) {
    return 'Password must be at least 6 characters.';
  }
  return null;
}

export function validateEmail(email: string): string | null {
  if (!email) {
    return 'Please enter an email address.';
  }
  const re = /^\S+@\S+$/;
  if (!re.test(email)) {
    return 'Please enter a valid email address.';
  }
  return null;
}

export function validateFullName(fullName: string): string | null {
  if (!fullName) {
    return 'Please enter your full name.';
  }
  return null;
}

export function msgFromFirebaseAuthError(firebaseError: AuthError): string {
  const code = firebaseError.code.replace(/^(auth\/)/, '');
  // @ts-ignore
  return firebaseErrorMessages[code]
  || `An unexpected error occurred. Error code: ${firebaseError.code}`;
}
