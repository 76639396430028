import { createApp } from 'vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
// @ts-ignore
import VTooltip from 'v-tooltip';
import Toast, { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './registerServiceWorker';

import { connectFirebaseEmulators } from './utils/firebase'; // inits firebase as a side effect

import router from './router';

import App from './App.vue';

import './index.css';
import store from './store';

// connectFirebaseEmulators();

const vueApp = createApp(App).use(store);
vueApp.config.globalProperties.$toast = useToast();
vueApp.use(router)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(VTooltip, {
    themes: {
      info: {
        $resetCss: true,
        triggers: [
          'focus',
          'hover',
          'touch',
        ],
        hideTriggers: (e: any) => e, // so clicking target doesn't hide tooltip
        placement: 'top',
        html: true,
      },
      error: {
        $resetCss: true,
        triggers: [
          'focus',
          'hover',
          'touch',
        ],
        hideTriggers: (e: any) => e, // so clicking target doesn't hide tooltip
        placement: 'top',
        offset: [0, 8],
        html: true,
      },
    },
  })
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 3,
    newestOnTop: true,
    position: 'top-center',
    timeout: 8000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
  .mount('#app');
