import {
  getAuth, connectAuthEmulator, sendEmailVerification, User,
} from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getStripePayments } from '@stripe/firestore-stripe-payments';
import { getBaseURL } from './misc';
import { FIREBASE_ENV } from './const';

window.location.replace('https://michael-jan.github.io/Loudness.fm/');

// eslint-disable-next-line import/prefer-default-export
export function connectFirebaseEmulators() {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectDatabaseEmulator(getDatabase(), 'localhost', 9000);
  connectFirestoreEmulator(getFirestore(), 'localhost', 8081);
  connectStorageEmulator(getStorage(), 'localhost', 9199);
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
}

const firebaseConfig = String(FIREBASE_ENV) === 'PROD' ? {
  apiKey: 'AIzaSyBq4EYMM3O9zo6Z9mzIxYWm-AdBhL0KSpk',
  authDomain: 'loudnessfm-69950.firebaseapp.com',
  projectId: 'loudnessfm-69950',
  storageBucket: 'loudnessfm-69950.appspot.com',
  messagingSenderId: '95955864631',
  appId: '1:95955864631:web:68236989ce982cfc5543b2',
  measurementId: 'G-MDCF8J94GT',
} : {
  apiKey: 'AIzaSyD7N9U5ahLqyMKFNNdfb1gz6Vc8Q0o0H50',
  authDomain: 'loudnessfm-dev.firebaseapp.com',
  projectId: 'loudnessfm-dev',
  storageBucket: 'loudnessfm-dev.appspot.com',
  messagingSenderId: '72783103687',
  appId: '1:72783103687:web:077ef0ae2574af2345e0e2',
  measurementId: 'G-BEG6NB4NBT',
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app, 'us-east1');
export const analytics = getAnalytics(app);
export const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users',
});
export const db = getFirestore(app);

export function mySendEmailVerification(user: User) {
  const baseURL = getBaseURL();
  return sendEmailVerification(user, { url: `${baseURL}/upload` });
}
