import { createRouter, createWebHistory } from 'vue-router';
import { getCurrentUser } from '@/utils/misc';
import { routes } from '@/router/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      next({ path: '/login', query: { redirect: to.fullPath } });
      return;
    }
  } else if (to.matched.some(record => record.meta.redirToUploadIfLoggedIn)) {
    if (user) {
      next('/upload');
      return;
    }
  } else if (to.matched.some(record => record.meta.redirToUploadIfEmailVerified)) {
    if (user && user.emailVerified) {
      next('/upload');
      return;
    }
  }
  next();
});

export default router;
