<template>
  <metainfo>
    <template #title="{ content }">
      {{
        content
          ? `${content} | Loudness.fm`
          : `Loudness.fm - Automatic Podcast Mastering`
      }}
    </template>
  </metainfo>
  <router-view />
</template>

<script>
import { getAuth } from 'firebase/auth';
import { getCurrentUser } from './utils/misc';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: '',
    };
  },
  async beforeCreate() {
    this.$store.dispatch('auth/setUser', (await getCurrentUser()) || null);
    getAuth().onAuthStateChanged(user => {
      this.$store.dispatch('auth/setUser', user || null);
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
